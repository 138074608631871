import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterActionPlans } from "../../../data/services/actions/action-plans-service";
import { FilterActionPlansDto } from "../../dtos/actions/filter-action-plans-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { createFilterActionPlansRequest } from "../../requests/actions/filter-action-plans-request";
import { ActionPlanResponse } from "../../responses/actions/action-plan-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { useTranslation } from "react-i18next";
import { ActionItems } from "../../../core/constants/translation-namespace";
import { translateText } from "../../../presentation/helpers/translate";

export const useFilterActionPlans = (
    filterActionPlansDto: FilterActionPlansDto,
    paginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    return useQuery(
        ["filterActionPlans", filterActionPlansDto, paginationDto],
        () =>
            filterActionPlans(
                url.baseUrl,
                createFilterActionPlansRequest(filterActionPlansDto, paginationDto)
            ),
        {
            keepPreviousData: true,
            select:  (response: Response<PaginationResponse<ActionPlanResponse>>) =>
                toDataTableRows(response, t),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toDataTableRows = (
    response: Response<PaginationResponse<ActionPlanResponse>>,
    t: any
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((actionPlan) => ({
        metadata: actionPlan.actionPlanId,
        columns: [
            {
                value: actionPlan.name,
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionPlan.actionItemCount?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: translateText(t, actionPlan.actionPlanStatus.toString(), ActionItems),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
