import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import {
    ActionItems,
    ActionPlans,
    Common,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    createNavigateToForSearch,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { associatedActionItemsColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { ActionItemEvidenceDto } from "../../../domain/dtos/actions/action-item-evidence-dto";
import { BasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { ActionItemSearchMode } from "../../../domain/enums/action-items/ActionItemSearchMode";
import { ActionItemRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useDownloadActionPlanFile,
    useGetActionPlanDetails,
} from "../../../domain/viewmodels/actions/action-plan-details-viewmodel";
import { useDisassociateActionItemFromActionPlan } from "../../../domain/viewmodels/actions/disassociate-action-item-viewmodel";
import { AssociateLink, CloneLink, CreateLink } from "../../atoms/SbLink";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";
import { FileGallery } from "../../organisms/FileGallery";

const ActionPlanDetailsContainer = (): JSX.Element => {
    const actionPlanId = Number(useParams().actionPlanId);

    const [actionPlanFileId, setActionPlanFileId] = useState<number | null>(null);
    const [associatedActionItemsPaginationDto, setAssociatedActionItemsPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);

    const [actionItemId, setActionItemId] = useState<number | null>(null);

    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const getActionPlanDetails = useGetActionPlanDetails(
        actionPlanId,
        associatedActionItemsPaginationDto
    );

    const disassociateActionItemFromActionPlan = useDisassociateActionItemFromActionPlan(
        actionPlanId,
        actionItemId
    );

    const downloadActionPlanFile = useDownloadActionPlanFile(actionPlanFileId);

    const actionPlanDetailsResponse = getActionPlanDetails[0];
    const actionPlanFilesResponse = getActionPlanDetails[1];
    const associatedActionItemsResponse = getActionPlanDetails[2];
    const actionPlanDetailsResponseData = actionPlanDetailsResponse.data;
    const actionPlanFilesResponseData = actionPlanFilesResponse.data;
    const associatedActionItemsResponseData = associatedActionItemsResponse.data;

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(
        areQueriesLoading(getActionPlanDetails) ||
            isMutationLoading(disassociateActionItemFromActionPlan),
        ActionPlanDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const onDownloadFile = (fileId: number): void => setActionPlanFileId(fileId);

    const navigateToViewActionItem = (dto: ActionItemEvidenceDto): void => {
        const params = [
            createNavigateSearchParameter(
                "actionItemInstanceId",
                dto.actionItemInstanceId?.toString()
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}`, params);
    };

    const navigateToEditActionItem = (dto: ActionItemEvidenceDto): void => {
        const params = [
            createNavigateSearchParameter(
                "actionItemInstanceId",
                dto.actionItemInstanceId?.toString()
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}/edit`, params);
    };

    const disassociateActionItem = (dto: ActionItemEvidenceDto): void => {
        setActionItemId(dto.actionItemId);
        disassociateActionItemFromActionPlan.mutate(dto, {
            onSuccess: async (response: Response<boolean>) => {
                toast.addToast(createSuccessToastProps([t("ActionItemDisassociatedSuccessfully")]));

                queryClient.refetchQueries(["filterAssociatedActionItems"]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <>
            <PageHeading>{t("ActionPlanDetails")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextActionPlanDetails")}</PageSubHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(actionPlanDetailsResponse) && (
                <ContentContainer>
                    <Row>
                        <Col md="2">
                            <DetailsLabel>{t("Name", { keyPrefix: Common })}</DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.name || "-"}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>{t("Description", { keyPrefix: Common })}</DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.description || "-"}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>{t("CreatedByUser", { keyPrefix: Users })}</DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.createdByUserFullName || "-"}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>
                                {t("CreatedByUserEmail", { keyPrefix: Users })}
                            </DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.createdByUserName || "-"}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>{t("DateCreated", { keyPrefix: Common })}</DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.dateCreatedLocal}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>
                                {t("LastModifiedBy", { keyPrefix: Common })}
                            </DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.modifiedByUserFullName || "-"}
                            </DetailsValue>
                        </Col>
                        <Col md="2">
                            <DetailsLabel>
                                {t("LastModifiedByUserName", { keyPrefix: Common })}
                            </DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.modifiedByUserName || "-"}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>{t("DateModified", { keyPrefix: Users })}</DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.dateModifiedLocal}
                            </DetailsValue>
                        </Col>

                        <Col md="2">
                            <DetailsLabel>
                                {t("OwnerEmailAddress", { keyPrefix: Common })}
                            </DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {actionPlanDetailsResponseData?.ownerUserEmail}
                            </DetailsValue>
                        </Col>
                        <Col md="2">
                            <DetailsLabel>
                                {t("ActionPlanStatus", { keyPrefix: ActionPlans })}
                            </DetailsLabel>
                        </Col>
                        <Col md="10">
                            <DetailsValue>
                                {t(actionPlanDetailsResponseData!.actionPlanStatus, {keyPrefix: ActionItems})}
                            </DetailsValue>
                        </Col>
                    </Row>
                </ContentContainer>
            )}

            {isQuerySuccessful(actionPlanFilesResponse) && (
                <>
                    <SectionVerticalSpace />

                    <SbAccordion title={t("AttachedEvidence", { keyPrefix: ActionItems })}>
                        <>
                            <SectionVerticalSpace />

                            <PageSubHeading>{t("ActionPlanFiles")}</PageSubHeading>
                            <SectionVerticalSpace />

                            <FileGallery
                                files={actionPlanFilesResponseData!}
                                onDownloadFile={onDownloadFile}
                                fileDownloadData={downloadActionPlanFile.data!}
                            />
                        </>
                    </SbAccordion>
                </>
            )}

            {isQuerySuccessful(associatedActionItemsResponse) && (
                <>
                    <SectionVerticalSpace />

                    <SbAccordion title={t("ActionItems", { keyPrefix: ActionItems })}>
                        <EndAlignedDiv>
                            <CreateLink
                                label={t("AddActionItem", { keyPrefix: ActionItems })}
                                navigateTo={createNavigateToForSearch(
                                    `${getPath(DrawerTitles.ActionItems)}/create`,
                                    [
                                        createNavigateSearchParameter(
                                            "actionPlanId",
                                            actionPlanId.toString()
                                        ),
                                    ]
                                )}
                            />
                            <AssociateLink
                                label={t("AddExistingActionItem", { keyPrefix: ActionItems })}
                                navigateTo={createNavigateToForSearch(
                                    `${getPath(
                                        DrawerTitles.ActionPlans
                                    )}/${actionPlanId}/associate-action-items`,
                                    [
                                        createNavigateSearchParameter(
                                            "actionItemSearchMode",
                                            ActionItemSearchMode.Associate.toString()
                                        ),
                                    ]
                                )}
                            />
                            <CloneLink
                                label={t("AddActionItemClone", { keyPrefix: ActionItems })}
                                navigateTo={createNavigateToForSearch(
                                    `${getPath(
                                        DrawerTitles.ActionPlans
                                    )}/${actionPlanId}/associate-action-items`,
                                    [
                                        createNavigateSearchParameter(
                                            "actionItemSearchMode",
                                            ActionItemSearchMode.AssociateClone.toString()
                                        ),
                                    ]
                                )}
                            />
                        </EndAlignedDiv>
                        <DataTable
                            noResultsMessage="No Action Items in this plan"
                            keyPrefix={ActionItems}
                            columns={associatedActionItemsColumnNames}
                            rows={associatedActionItemsResponseData!.rows}
                            viewItem={navigateToViewActionItem}
                            editItem={navigateToEditActionItem}
                            deleteItem={
                                hasRoleTypeInGroup(auth.userRoles, ActionItemRoleGroup.WriteRoles)
                                    ? (disassociateActionItem: ActionItemEvidenceDto) => {
                                          const params = [
                                              createNavigateSearchParameter(
                                                  "actionPlanId",
                                                  actionPlanId.toString()
                                              ),
                                              createNavigateSearchParameter(
                                                  "actionItemId",
                                                  disassociateActionItem.actionItemId!.toString()
                                              ),
                                          ];

                                          navigateSearch(
                                              `${getPath(DrawerTitles.ActionPlans)}/disassociate`,
                                              params
                                          );
                                      }
                                    : undefined
                            }
                            totalItems={associatedActionItemsResponseData!.recordCount}
                            paginationDto={associatedActionItemsPaginationDto}
                            setPaginationDto={setAssociatedActionItemsPaginationDto}
                        />
                    </SbAccordion>
                </>
            )}
        </>
    );
};

export default ActionPlanDetailsContainer;
