import { useQueries, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    downloadActionPlanFile,
    filterActionPlanFiles,
    filterAssociatedActionItems,
    getActionPlanDetails,
    getActionPlanDetailsFromActionItem,
} from "../../../data/services/actions/action-plans-service";
import { ActionItemEvidenceDto } from "../../dtos/actions/action-item-evidence-dto";
import { ActionPlanDetailsDto } from "../../dtos/actions/action-plan-details-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FileDownloadDto } from "../../dtos/file-storage/file-download-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import { createFilterAssociatedActionItemsRequest } from "../../requests/actions/filter-associated-action-items-request";
import { AssociatedActionItemResponse } from "../../responses/actions/associated-action-item-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { useTranslation } from "react-i18next";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { translateText } from "../../../presentation/helpers/translate";

export const useGetActionPlanDetails = (
    actionPlanId: number,
    actionItemsPaginationDto: BasePaginationDto
): [
    UseQueryResult<ActionPlanDetailsDto, HTTPError>,
    UseQueryResult<FileDto[], HTTPError>,
    UseQueryResult<PaginatedTableDto<ActionItemEvidenceDto>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    return useQueries({
        queries: [
            {
                queryKey: ["getActionPlanDetails", actionPlanId],
                queryFn: () => getActionPlanDetails(url.baseUrl, actionPlanId),
                select: ActionPlanDetailsDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterActionPlanFiles", actionPlanId],
                queryFn: () => filterActionPlanFiles(url.baseUrl, actionPlanId),
                select: FileDto.constructFromActionPlanFileDownloadResponses,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterAssociatedActionItems", actionItemsPaginationDto, actionPlanId],
                queryFn: () =>
                    filterAssociatedActionItems(
                        url.baseUrl,
                        createFilterAssociatedActionItemsRequest(
                            actionItemsPaginationDto,
                            actionPlanId
                        )
                    ),
                keepPreviousData: true,
                enabled: actionPlanId != null,
                select: (response: Response<PaginationResponse<AssociatedActionItemResponse>>) =>
                    toAssociatedActionItemsToDataTableRows(response, t),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionPlanDetailsDto, HTTPError>,
        UseQueryResult<FileDto[], HTTPError>,
        UseQueryResult<PaginatedTableDto<ActionItemEvidenceDto>, HTTPError>,
    ];
};

export const useGetActionPlanDetailsFromActionItem = (
    actionItemId: number
): UseQueryResult<ActionPlanDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getActionPlanDetailsFromActionItem", actionItemId!],
        () => getActionPlanDetailsFromActionItem(url.baseUrl, actionItemId),
        {
            select: ActionPlanDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDownloadActionPlanFile = (
    actionPlanFileId: number | null
): UseQueryResult<FileDownloadDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["downloadActionPlanFile", actionPlanFileId!],
        () => downloadActionPlanFile(url.baseUrl, actionPlanFileId!),
        {
            enabled: actionPlanFileId != null,
            select: FileDownloadDto.constructFromActionPlanFileDownloadResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toAssociatedActionItemsToDataTableRows = (
    response: Response<PaginationResponse<AssociatedActionItemResponse>>,
    t: any
): PaginatedTableDto<ActionItemEvidenceDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((associatedActionItem) => ({
        metadata: new ActionItemEvidenceDto(
            associatedActionItem.actionItemId,
            associatedActionItem.actionItemInstanceId
        ),
        columns: [
            {
                value: associatedActionItem.actionItemId?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: associatedActionItem.name?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: translateText(t, associatedActionItem.actionItemImportanceResponse.name?.toString(), Common),
                type: DataTableColumnTypes.Text,
            },
            {
                value: `${associatedActionItem.accountableUser?.firstName} ${associatedActionItem.accountableUser?.lastName}`,
                type: DataTableColumnTypes.Text,
            },
            {
                value: `${associatedActionItem.responsibleUser?.firstName} ${associatedActionItem.responsibleUser?.lastName}`,
                type: DataTableColumnTypes.Text,
            },
            {
                value: translateText(t, associatedActionItem.actionInstanceStatusResponse.name?.toString(), ActionItems),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(associatedActionItem?.startDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
            {
                value:
                    associatedActionItem.dueDateUtc &&
                    toDateAndTimeFormat(associatedActionItem?.dueDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
