import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { createReferentialLink } from "../../../data/services/referential-link-types/referential-link-service";
import { lookupReferentialLinkTypes } from "../../../data/services/lookup/lookup-service";
import {
    AssociateReferentialLinkTypeDto,
    toAssociateReferentialLinkTypeDto,
} from "../../dtos/checklists/associate-referential-link-type-dto";
import { createCreateReferentialLinkRequest } from "../../requests/referential-link-types/create-referential-link-request";
import { Response } from "../../responses/common/response-response";

export const useGetAllAssociateReferentialLinkTypes = (): UseQueryResult<
    AssociateReferentialLinkTypeDto[],
    HTTPError
> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["lookupReferentialLinkTypes"], () => lookupReferentialLinkTypes(url.baseUrl), {
        select: toAssociateReferentialLinkTypeDto,
        onError: errorResponseToDisplayHandler,
    });
};

interface CreateParameters {
    referentialLinkTypeId: number;
    formData: FormData;
}

export const useCreateAssociateReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    CreateParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: CreateParameters) => {
        const { formData, referentialLinkTypeId } = mutationKey;

        const request = createCreateReferentialLinkRequest(
            referentialLinkTypeId,
            formData.get("referentialLinkValue")! as string
        );

        return createReferentialLink(url.baseUrl, request);
    });
};
